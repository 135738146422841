/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from "react";
import { SIGN_UP_PROCESS_STEP } from "../../../commons/signup.constants";
import { useCompanyInfo, useIsMobile } from "../../../hooks/common.hooks";

const signupTitle = (signupProcess, name, isMobile, companyName) => {
  switch (signupProcess) {
    case SIGN_UP_PROCESS_STEP.VERIFY_PHONE_NUMBER:
      return (
        <>
          Hey <span className="header-background">{name}</span> are you there?
        </>
      );

    case SIGN_UP_PROCESS_STEP.CONNECT_BANK:
      return (
        <>
          Nice to see you’re alive :) {!isMobile && <br />}
          Let’s connect your{" "}
          <span className="header-background">accounts.</span>{" "}
        </>
      );
    case SIGN_UP_PROCESS_STEP.SIGN_UP_SUCCESS:
      return "";
    default:
      return (
        <>
          Hello and Welcome.
          <br /> We Are{" "}
          <span className="header-background">{companyName}.</span>{" "}
        </>
      );
  }
};
const signupDescription = (signupProcess, companyName) => {
  switch (signupProcess) {
    case SIGN_UP_PROCESS_STEP.COLLECT_BASIC_INFO:
      return `Getting you the best financial outcomes is what we at ${companyName} are all about. We just need a few details to set you up.`;

    default:
      return "";
  }
};

const SignupHeader = ({ name = "", signupProcess, setSignupProcess }) => {
  const { data: companyInfo } = useCompanyInfo();
  const handleVerifyKeyDown = (e) => {
    if (e.key === "1") {
      setSignupProcess(SIGN_UP_PROCESS_STEP.VERIFY_PHONE_NUMBER);
    }
    if (e.key === "2") {
      setSignupProcess(SIGN_UP_PROCESS_STEP.CONNECT_BANK);
    }
    if (e.key === "3") {
      setSignupProcess(SIGN_UP_PROCESS_STEP.RETRIEVE_BANK_DATA);
    }
    if (e.key === "4") {
      setSignupProcess(SIGN_UP_PROCESS_STEP.CONNECT_PROPERTY);
    }
    if (e.key === "5") {
      setSignupProcess(SIGN_UP_PROCESS_STEP.SET_BUYING_GOAL);
    }
    if (e.key === "6") {
      setSignupProcess(SIGN_UP_PROCESS_STEP.SIGN_UP_SUCCESS);
    }
  };
  const isMobile = useIsMobile();
  return (
    <div className="signup-header">
      <div
        className="signup-title"
        tabIndex="0"
        onKeyDown={handleVerifyKeyDown}
      >
        {signupTitle(
          signupProcess,
          name.split(" ")[0],
          isMobile,
          companyInfo?.name || "WealthX"
        )}
      </div>
      <div className="signup-desc">
        {signupDescription(signupProcess, companyInfo?.name || "WealthX")}
      </div>
    </div>
  );
};

export default SignupHeader;
