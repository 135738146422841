import { Router } from "@reach/router";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { SIGN_UP_PROCESS_STEP } from "../../commons/signup.constants";
import { useCompanyInfo } from "../../hooks";
import NewSignup from "./components/sign-up-container";
import SignupHeader from "./components/sign-up-header";
import "./scss/signup.scss";

const Signup = ({ location }) => {
  const { staffCode = "" } = queryString.parse(location.search);
  const { data: companyInfo } = useCompanyInfo();
  const [signupProcess, setSignupProcess] = useState(
    SIGN_UP_PROCESS_STEP.COLLECT_BASIC_INFO
  );
  const [params, setParams] = useState({
    codeId: "",
    email: "",
    password: "",
    firstName: "",
    lastName: " ",
    phoneNumber: "",
    referralBy: "",
    staffCode,
  });

  const handleSetSignupProcess = (value) => {
    setSignupProcess(value);
  };

  const handleSetParams = (value) => {
    setParams(value);
  };

  const { firstName = "" } = params;

  useEffect(() => {
    if (companyInfo.id) {
      setParams((prevState) => ({
        ...prevState,
        companyId: companyInfo.id,
      }));
    }
  }, [companyInfo.id]);

  return (
    <div className="signup-container">
      <SignupHeader
        name={firstName}
        signupProcess={signupProcess}
        setSignupProcess={handleSetSignupProcess}
      />
      <Router basepath="/signup" className="signup-body">
        <NewSignup
          path="/*"
          signupProcess={signupProcess}
          setSignupProcess={handleSetSignupProcess}
          params={params}
          setParams={handleSetParams}
        />
      </Router>
    </div>
  );
};

export default Signup;
